<template>
  <div>
      <div>
        <v-card flat tile class="transparent" @click="ActivateActiveChildComponent">
         <QuickAlerts :Alerts="PrimaryTimesheetAlerts"  
                />
          <QuickCounters :Counters="PrimaryTimesheetCounters"  
                />
          3. Chart (line type chart position "left" makes for small view) - charts closed this week (I think) 
        </v-card>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ScheduledBookingViewComp from '@/components/SuitePlugins/CalendarTables/ScheduledBookingViewComp'
import QuickAlerts from '@/components/Dashboards/QuickAlerts';
import QuickCounters from '@/components/Dashboards/QuickCounters';
export default {
    props: ['System','SystemEntities','RelatedObj','ChildComponent'],
    components: {ScheduledBookingViewComp,QuickAlerts,QuickCounters},
    data() {
        return {
            ViewBy: 'User',
            UserSearch: '',
            Timesheets: [],
            focus: '',
            WeekDay: 0,
            type: 'week',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
            },
            start: null,
            end: null,
            today: ''
        }
    },
    computed:{
      UserswithActiveJobs(){
        return this.NonGuestUsers.filter(user => {
          return user.CurrentJob
        })
      },
      UserswithoutActiveJobs(){
        return this.NonGuestUsers.filter(user => {
          return !user.CurrentJob
        })
      },
      NonGuestUsers(){
        return this.UsersArray.filter(user => {
          return !user.IsGuestUser
        })
      },
      TotalProjects(){
        return this.ProjectEventsViewFormatted
      },
      TotalMinutes(){
        return this.ComputedTimesheetsArray.map(timesheet => {          
          let total = Math.floor((timesheet.DurationMS / (1000 * 60)) % 60)
          //console.log(timesheet.DurationMS,total)
          return total
        }).reduce((a, b) => a + b, 0)
      },
      PrimaryTimesheetCounters(){
            return [
              // #ff980012
                {CounterIcon: 'mdi-tag-faces',Data: this.NonGuestUsers,CounterInteger: this.NonGuestUsers.length, Title: 'Team Members',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#607D8B'},FontColor: {hex: '#607D8B'}},
                {CounterIcon: 'mdi-account-clock',Data: this.UserswithActiveJobs,CounterInteger: this.UserswithActiveJobs.length, Title: 'Active Jobs',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#607D8B'},FontColor: {hex: '#607D8B'}},
                {CounterIcon: 'mdi-account-clock',Data: this.TotalProjects,CounterInteger: this.TotalProjects.length, Title: 'Total Jobs',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#607D8B'},FontColor: {hex: '#607D8B'}},
                 {CounterIcon: 'mdi-account-clock',Data: this.ComputedTimesheetsArray,CounterInteger: this.TotalMinutes, Title: 'Total Minutes',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#607D8B'},FontColor: {hex: '#607D8B'}},
            ]
        },
      PrimaryTimesheetAlerts(){
            return [
                {Title: 'Inactive Team Members',Array: this.UserswithoutActiveJobs, TableName: 'Team Members',IncompleteName: 'Inactive'},
            ]
        },
        ComputedSystemEntities(){
          return this.SystemEntities.filter(entity => {
            return this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
          })
        },
        datetitle () {
        const { start, end } = this
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
          case 'day':
            return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
        WeekDays(){
          //console.log(this.start,this.end)
    let array= []
    if(this.start && this.end){
    let start = new Date(this.start.date)
    let sunday = start
    let monday = new Date(sunday)
    monday.setDate(sunday.getDate()-1+2)
    let tuesday = new Date(monday)
    tuesday.setDate(monday.getDate()-1+2) 
    let wednesday = new Date(tuesday)
    wednesday.setDate(tuesday.getDate()-1+2)
    let thursday = new Date(wednesday)
    thursday.setDate(wednesday.getDate()-1+2)
    let friday = new Date(thursday)
    friday.setDate(thursday.getDate()-1+2)  
    let end = new Date(this.end.date)
    end.setHours(23,59,59,999)
       array= [
        {
          Short: 'Su',
          Day: sunday.getDate(),
          DateObject: sunday,
          DateString: format(sunday,'yyyy-MM-dd')
        },
        {
          Short: 'Mo',
          Day: monday.getDate(),
          DateObject: monday,
          DateString: format(monday,'yyyy-MM-dd')
        },
        {
          Short: 'Tu',
          Day: tuesday.getDate(),
          DateObject: tuesday,
          DateString: format(tuesday,'yyyy-MM-dd')
        },
        {
          Short: 'We',
          Day: wednesday.getDate(),
          DateObject: wednesday,
          DateString: format(wednesday,'yyyy-MM-dd')
        },
        {
          Short: 'Th',
          Day: thursday.getDate(),
          DateObject: thursday,
          DateString: format(thursday,'yyyy-MM-dd')
        },
        {
          Short: 'Fr',
          Day: friday.getDate(),
          DateObject: friday,
          DateString: format(friday,'yyyy-MM-dd')
        },
        {
          Short: 'Sa',
          Day: end.getDate(),
          DateObject: end,
          DateString: format(end,'yyyy-MM-dd')
        }
      ]
    }
    
    
    return array
  },
        userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userLoggedIn () {
    return this.$store.getters.user
  },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
        UsersArray(){
            return this.$store.state.Users
        },
        AllEventsViewFormatted(){
            return this.DataPrepped.map(event => {
                let eventobj = Object.assign({},event) 
                eventobj.name = eventobj.RecordName                 
                //console.log(eventobj)  
                return eventobj
                
            })
            .filter(event => {
            if(this.focus){
            let start = new Date(this.focus)
            let end = new Date(this.focus)
            end.setHours(23,59,59,999)
            //console.log(event.StartObject,this.WeekDays[0].DateObject,event.EndObject,this.WeekDays[6].DateObject)
            //return event.StartObject >= this.WeekDays[0].DateObject && event.EndObject <= this.WeekDays[6].DateObject
            return event.StartObject >= start && event.EndObject <= end || event.IsUser
            }
            else{
            return event
            }
            
        }).sort((a, b) => {
                //console.log('sorting again')
                //console.log(a.start)
                var key1 = a.StartObject;
                var key2 = b.StartObject;

                if (key1 < key2) {
                    return -1;
                } else if (key1 == key2) {
                    return 0;
                } else {
                    return 1;
                }
            })
        },
        DayCount(){
            return this.start && this.start.month ? this.DaysofMonth(this.start.month,this.start.year) : 0
        },
        TypeHeaders(){
            let array = [
                {text: 'Full Name',value: 'Full_Name'},
            ]
            if(this.type === 'day'){
                array.push({text: 'Total',value: 'Total'})
            }
            else if(this.type === 'week'){
                array.push({text: 'Su',value: 'Su'})
                array.push({text: 'Mo',value: 'Mo'})
                array.push({text: 'Tu',value: 'Tu'})
                array.push({text: 'We',value: 'We'})
                array.push({text: 'Th',value: 'Th'})
                array.push({text: 'Fr',value: 'Fr'})
                array.push({text: 'Sa',value: 'Sa'})
                array.push({text: 'Total',value: 'Total'})
            }
            else if(this.type === 'month'){      
                console.log(this.DayCount)
                for(var x = 1; x <= this.DayCount; x++){	
                    array.push({text: x,value: x+'Count'})
                    if(x === this.DayCount){
                        array.push({text: 'Total',value: 'Total'})
                    }
                }
            }
            return array
        },
        ComputedHeaders(){
            return this.ViewBy === 'Project' ? [] 
            : this.TypeHeaders
        },
        ProjectEventsViewFormatted(){
            let array = []
            this.TimeSheetsViewFormatted
            .map(timesheet => {
                let oncheck = array.find(obj => obj.RelatedObjid === timesheet.RelatedObjid)
                if(!oncheck){
                    array.push(timesheet)
                }
                else{
                    oncheck.DurationMS = oncheck.DurationMS+timesheet.DurationMS
                    oncheck.Total = this.msToTime(oncheck.DurationMS)
                }
            })
            return array
        },
        TimeSheetsViewFormatted(){
            return this.ProjectDataPrepped.map(event => {
                    let eventobj = Object.assign({},event) 
                    eventobj.name = eventobj.RecordName                 
                    //console.log(eventobj)  
                    return eventobj                    
                })
                .filter(event => {
                if(this.start && this.end){
                let start = new Date(this.start.date)
                let end = new Date(this.end.date)
                end.setHours(23,59,59,999)
                //return event.StartObject >= this.WeekDays[0].DateObject && event.EndObject <= this.WeekDays[6].DateObject
                return event.StartObject >= start && event.EndObject <= end
                }
                else{
                return event
                }                
            }).map(item => {
                item.Total = this.msToTime(item.DurationMS)
                return item
            })
        },
        ProjectDataPrepped(){
            return this.ComputedTimesheetsArray.map(item => {
                item.RecordName = item.RecordName
                item.StartObject = item.Created_On.toDate()
                item.EndObject = item.End_Date.toDate()
                item.start = this.TimestampFormatterSTRING(item.StartObject)
                item.end = this.TimestampFormatterSTRING(item.EndObject)
                //console.log('item',item)
                return item
            })
        },
        UserDataPrepped(){
            return this.UsersArray.map(item => {
                item.RecordName = item.Full_Name
                item.IsUser = true
                item.RelatedObj = {}
                item.start = '2000-01-01'
                item.end = '2000-01-01'
                item.RangeEntries = this.TimeSheetsViewFormatted.filter(timesheet => {
                    return timesheet.Userid === item.id
                })
                item.Milliseconds = item.RangeEntries
                .map(timesheet => {
                    return timesheet.DurationMS
                })
                .reduce((a, b) => a + b, 0)
                item.Total = this.msToTime(item.Milliseconds)
                //console.log(this.WeekDays,item)
                this.WeekDays.map((weekday,headindex) => {
                    let Milliseconds = item.RangeEntries
                    .filter(timesheet => {
                        if(headindex === 6){                            
                            let daystart = new Date(weekday.DateObject)
                            daystart.setHours(0,0,0,0)
                            return timesheet.StartObject >= daystart && timesheet.EndObject < weekday.DateObject
                        }
                        else{
                            let dayend = new Date(weekday.DateObject)
                            dayend.setHours(23,59,59,999)
                          return timesheet.StartObject >= weekday.DateObject && timesheet.EndObject < dayend  
                        }                        
                    })
                    .map(timesheet => {
                        return timesheet.DurationMS
                    }).reduce((a, b) => a + b, 0)
                    item[weekday.Short] = this.msToTime(Milliseconds)
                    item[weekday.Short+'Milliseconds'] = Milliseconds
                })
                for(var x = 1; x <= this.DayCount; x++){	
                    let startdate = new Date(this.start.date)                    
                    startdate.setDate(x)
                    let nextdate = new Date(startdate)
                    nextdate.setDate(x-1+2)
                    let Milliseconds = item.RangeEntries
                    .filter(timesheet => {
                        return timesheet.StartObject >= startdate && timesheet.EndObject < nextdate                          
                    })
                    .map(timesheet => {
                        return timesheet.DurationMS
                    }).reduce((a, b) => a + b, 0)
                    item[x+'Count'] = this.msToTime(Milliseconds)
                    item[x+'CountMilliseconds'] = Milliseconds
                }
                return item
            }) 
        },
        DataPrepped(){
            return this.ViewBy === 'Project' ? this.ProjectDataPrepped : this.UserDataPrepped
        },
        ComputedUsersArray(){
            return this.UsersArray.filter(user => {
                if(this.UserSearch){
                    return user.Full_Name.toLowerCase().includes(this.UserSearch.toLowerCase())
                }
                else{
                    return user
                }
            })
        },
        ComputedTimesheetsArray(){
            return this.Timesheets.map(item => {
                let userobj = this.UsersArray.find(obj => obj.id === item.Userid)
                if(userobj){
                    item.User.Profile_Photo = userobj.Profile_Photo
                }
                // let intergerrollup = item.Intervals.map(int => {
                //     return int.interval.INT
                // }).reduce((a, b) => a + b, 0)
                let start = item.Created_On.toDate()
                let starttime = start.getTime()
                let end = item.End_Date.toDate()
                let endtime = end.getTime()
                let intergerrollup = endtime-starttime
                item.DurationMS = intergerrollup
                item.DurationMinutes = this.millisToMinutesAndSeconds(item.DurationMS)
                return item
            })
        },
    },
    created(){
        var d = new Date();
      var n = d.toISOString();
      this.today = n.split('T')[0]
      this.setToday()
        let query = db.collection('timesheets')
        if(this.RelatedObj){
          console.log(this.RelatedObj)
          query = query.where('RelatedObjid','==',this.RelatedObj.id)
          this.ViewBy = 'Project'
          this.type = 'month'
        }
        this.GetTimesheets(query)
    },
    methods:{
      ActivateActiveChildComponent(){
          this.$emit('ActivateActiveChildComponent',this.ChildComponent)
      },
        DaysofMonth(month,year) {
        return new Date(year, month, 0).getDate();
        },
        nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        updatefocus(index){
      this.focus = this.WeekDays[index].DateString
      //console.log(this.WeekDays,this.focus)
    },
        updateRange ({ start, end }) {
      //console.log('change')
      this.start = start
        this.end = end
        
    },
        setToday () {
        
        this.focus = this.today
        setTimeout(() => {
         let todayofweek = this.WeekDays.find(obj => obj.DateString === this.today)
        //console.log(todayofweek,this.WeekDays,this.today)
        let todayfweekindex = this.WeekDays.indexOf(todayofweek)
        this.WeekDay = todayfweekindex
        }, 100);
        
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      msToTimeClass(duration) {

        let seconds = Math.floor((duration / 1000) % 60)
        let minutes = Math.floor((duration / (1000 * 60)) % 60)
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
        //console.log('seconds',seconds)
          if(hours > 9){
            return 'warning' 
          }
          else if(hours > 5){
            return 'blue' 
          }
          else if(hours > 3){
            return 'blue lighten-1'
          }
          else if(seconds !== 0 || hours !== 0){
            return 'blue lighten-3'
          }
          else{
            return 'background'
          }
        },
      msToTime(duration) {

        let seconds = Math.floor((duration / 1000) % 60)
        let minutes = Math.floor((duration / (1000 * 60)) % 60)
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + "h " + minutes + "m "
        },
        millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        },
        GetTimesheets(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let timesheetobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    this.Timesheets.push(timesheetobj)
                }
            })
            })
        }
    }
}
</script>

<style>

</style>
